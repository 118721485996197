import HeaderSectionComponent from '@wix/thunderbolt-elements/src/components/Sections/HeaderSection/viewer/HeaderSection';


const HeaderSection = {
  component: HeaderSectionComponent
};


export const components = {
  ['HeaderSection']: HeaderSection
};

